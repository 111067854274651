import * as React from "react";
import {
  Heading,
  VStack
} from "@chakra-ui/react";

import { IconFactory } from "../../ScalableVectorGraphics";


interface Props {
  heading: string
  icon: string
  width: object | string
}


export const Benefit: React.FC<Props> = ( props ) => {
  return (
    <VStack
      justify="center"
      align="flex-start"
      spacing={ 6 }
      minW={ props.width }
      w="100%"
    >
      <IconFactory
        icon={ props.icon }
        w="60px"
        h="60px"
        color="primary.900"
      />
      <Heading
        as="h5"
        fontSize="20px"
        fontWeight={ 700 }
      >
        { props.heading }
      </Heading>
    </VStack>
  )
}
