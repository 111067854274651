import * as React from "react";
import {
  Box,
  Flex
} from "@chakra-ui/react";
import {
  keyframes
} from "@chakra-ui/react";

import {
  Benefit
} from "./Items";


interface Props {
  itemWidth: number
  template: string
  nodes: any[]
}


const ticker = ( scrollLength: number ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;


export const Ticker: React.FC<Props> = ( props ) => {
  const tickerAnimation = `${ ticker( props.itemWidth * props.nodes.length ) } 60s linear infinite`;

  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'benefit':
        return (
          <Benefit
            key={ _index }
            heading={ node.node.frontmatter.heading }
            icon={ node.node.frontmatter.icon }
            width={ `${ props.itemWidth }px` }
          />
        );
      default:
        return null;
    }
  })

  return (
    <Box
      w="100%"
      overflow="hidden"
      boxSizing="content-box"
    >
      <Flex
        boxSizing="content-box"
        animation={ tickerAnimation }
      >
        { nodeList }
        { nodeList }
      </Flex>
    </Box>
  )
}
