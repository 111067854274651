import * as React from "react";
import { GatsbyImage, IGatsbyImageData, withArtDirection } from "gatsby-plugin-image";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link
} from "@chakra-ui/react";


interface Props {
  mobileImage: IGatsbyImageData
  desktopImage: IGatsbyImageData
}


export const CallToAction: React.FC<Props> = ( props ) => {
  const sources = withArtDirection( props.mobileImage, [
    {
      media: `(min-width: 1024px)`,
      image: props.desktopImage,
    }
  ]);

  return (
    <Grid
      w="100%"
      h={{
        base: '420px',
        lg: '252px',
        xl: '352px',
        '2xl': '470px'
      }}
    >
      <Image
        as={ GatsbyImage }
        image={ sources }
        alt=""
        gridArea="1/1"
      />
      <Box
        gridArea="1/1"
        position="relative"
      >
        <Flex
          direction={{
            base: 'column',
            lg: 'row'
          }}
          w="100%"
          h="100%"
        >
          <Box
            flex="1"
            bg="transparent"
          >
          </Box>
          <Box
            flex="1"
            bg="background.burgundy.800"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              w="100%"
              h="100%"
            >
              <Heading
                as="h2"
                fontSize="48px"
                fontWeight={ 700 }
                color="white"
              >
                Call Us Today
              </Heading>
            </Flex>
          </Box>
        </Flex>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Button
            as={ Link }
            href="tel:+16477216065"
            isExternal
            variant="solid"
            w="194px"
            h="52px"
            bg="white"
            borderRadius="28px"
            borderColor="white"
            color="primary.900"
            fontWeight={ 500 }
            _hover={{
              bg: "primary.900",
              borderColor: "primary.900",
              color: "white",
              textDecoration: "none"
            }}
          >
            +1 647 721 6065
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}
