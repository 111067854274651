import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Button,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";

import {
  Map
} from "./Elements";


interface Props {
  heading: React.ReactNode | string
  description: string
  slug: string

  layout: string
}


export const Absolute: React.FC<Props> = ( props ) => {
  const renderElement = ( layout: string ) => {
    switch ( layout ) {
      case 'map':
        return (
          <Map />
        );
      default:
        return null;
    }
  }

  return (
    <VStack
      display={{
        lg: 'block'
      }}
      position="relative"
      w="100%"
      h={{
        base: 'auto',
        lg: '700px',
        xl: '800px'
      }}
    >
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing="24px"
        w={{
          base: '100%',
          sm: '500px'
        }}
      >
        <Heading
          as="h2"
          fontSize="48px"
          fontWeight={ 700 }
          color="text.primary"
          maxW="480px"
          w="100%"
        >
          { props.heading }
        </Heading>
        <Text
          fontSize="16px"
          fontWeight={ 400 }
          color="text.secondary"
        >
          { props.description }
        </Text>
        <Button
          as={ GatsbyLink }
          to={ props.slug }
          variant="link"
          color="primary.900"
          _hover={{
            opacity: '0.6',
            textDecoration: 'none'
          }}
        >
          Learn more
        </Button>
      </VStack>
      { renderElement( props.layout ) }
    </VStack>
  )
}
