import * as React from "react";
import { keyframes } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  VStack
} from "@chakra-ui/react";

import {
  CanadaGraphic
} from "../../ScalableVectorGraphics/Graphics/CanadaGraphic";


const pulsate = keyframes`
  0% {
    transform: scale( 0.95 );
    box-shadow: 0 0 0 0 rgba( 237, 27, 36, 0.7 );
  }

  70% {
    transform: scale( 1 );
    box-shadow: 0 0 0 40px rgba( 237, 27, 36, 0 );
  }

  100% {
    transform: scale( 0.95 );
    box-shadow: 0 0 0 0 rgba( 237, 27, 36, 0 );
  }
`;


export const Map: React.FC = () => {
  return (
    <Box
      position={{
        base: 'static',
        lg: 'absolute'
      }}
      left={ 0 }
      bottom={ 0 }
      w="100%"
      h={{
        base: '380px',
        sm: '520px',
        md: '620px',
        lg: '100%'
      }}
      zIndex={ -2 }
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
      >
        <Box
          position="absolute"
          right={{
            sm: 0
          }}
          bottom={{
            base: '17%',
            sm: '56px',
            md: '50px',
            lg: 0
          }}
          maxW={{
            base: '400px',
            sm: '100%'
          }}
          w={{
            base: '100%',
            lg: 'auto'
          }}
        >
          <CanadaGraphic
            w={{
              base: '100%',
              lg: '683px',
              xl: '854px'
            }}
            h="auto"
            color="background.lightgray"
          />
        </Box>
        <Stack
          direction={{
            base: 'column-reverse',
            lg: 'column'
          }}
          align="flex-start"
          spacing="16px"
          position="absolute"
          bottom={{
            base: '0',
            lg: '24px',
            xl: '32px'
          }}
          left={ 0 }
          maxW={{
            base: '280px',
            sm: '100%'
          }}
          w={{
            base: '70%',
            sm: '69%',
            lg: '79%',
            xl: '79%'
          }}
        >
          <VStack
            justify="flex-start"
            align="flex-start"
            spacing="8px"
          >
            <Heading
              as="h4"
              fontSize="24px"
              fontWeight={ 600 }
              color="text.primary"
            >
              Main Office
            </Heading>
            <Heading
              as="h5"
              fontSize="16px"
              fontWeight={ 400 }
              color="text.secondary"
            >
              Toronto
            </Heading>
          </VStack>
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
          >
            <Box
              w={{
                base: '80%',
                lg: '91%',
                xl: '93%'
              }}
              h="2px"
              bg="border.gainsboro"
            />
            <Box
              w="11px"
              h="11px"
              bg="white"
              border="1px"
              borderColor="border.gainsboro"
              borderRadius="50%"
              boxShadow="0 0 0 0 rgba( 237, 27, 36, 1 )"
              transform="scale( 1 )"
              animation={ `${ pulsate } 2s infinite` }
            />
          </Flex>
        </Stack>
      </Box>
    </Box>
  )
}
